import { MENU_WIDGET_COMPONENT_IDS } from '../../consts';
import type { PopulatedMenu } from 'root/types';

export class MenuController {
  constructor(private $bindAll: (data: any) => void) {}

  init(menu: PopulatedMenu) {
    this.$bindAll({
      [MENU_WIDGET_COMPONENT_IDS.menuTitle]: {
        text: () => menu?.name || '',
      },
      [MENU_WIDGET_COMPONENT_IDS.menuDescription]: {
        text: () => menu?.description || '',
        deleted: () => !menu?.description,
      },
      [MENU_WIDGET_COMPONENT_IDS.columns]: {
        data: () => ({ sections: menu?.sections }),
      },
    });
    return true;
  }
}
