import { settleApiCall } from './settleApiCall';
import type { ReportError } from 'root/types';

export const getMonitoredApiCall = async <T>(
  callback: () => Promise<T>,
  reportError: ReportError,
): Promise<{ data: T | undefined; error: Error | undefined }> => {
  const { data, error } = await settleApiCall<T>(callback);

  if (error) {
    reportError(error);
  }

  return { data, error };
};
