import { chunkArray } from './chunkArray';
import type {
  Menu,
  Section,
  MenusEntity,
  ShowcasePopulatedItem,
  GetAllResponse,
  IMenusHTTPClient,
} from 'root/types';

export const completeMissingEntities = async (
  menus: Menu[],
  sections: GetAllResponse<Section>,
  getSections: IMenusHTTPClient<Section>['getAll'],
  populatedItems: GetAllResponse<ShowcasePopulatedItem>,
  getPopulatedItems: IMenusHTTPClient<ShowcasePopulatedItem>['getAll'],
) => {
  const sectionIds = [
    ...new Set(menus.flatMap((menu) => menu.sectionIds || [])),
  ];
  await completeMissingEntity(sectionIds, sections, getSections);
  const itemIds = [
    ...new Set(sections.data.flatMap((section) => section.itemIds || [])),
  ];
  await completeMissingEntity(itemIds || [], populatedItems, getPopulatedItems);
};

export const completeMissingEntity = async <T extends MenusEntity>(
  ids: string[],
  entities: GetAllResponse<T> | undefined,
  getEntities: IMenusHTTPClient<T>['getAll'],
) => {
  if (entities?.pagingMetadata?.hasNext) {
    const missingEntitiesIds = ids.reduce((acc: string[], id: string) => {
      const currEntity = entities.data.find((entity) => entity.id === id);
      return currEntity ? acc : [...acc, id];
    }, []);
    if (missingEntitiesIds.length > 0) {
      const missingEntitiesIdsChunks = chunkArray(missingEntitiesIds, 500);
      const missingEntities = await Promise.all(
        missingEntitiesIdsChunks.map((missingIds: string[]) =>
          getEntities({ ids: missingIds }),
        ),
      );
      entities.data.push(...missingEntities.flatMap((entity) => entity.data));
    }
  }
};
