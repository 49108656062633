export const MENU_WIDGET_COMPONENT_IDS = {
  menuTitle: '#menuTitle',
  menuDescription: '#menuDescription',
  columns: '#section1',
};

export const SECTION_WIDGET_COMPONENT_IDS = {
  sectionsRepeater: '#sectionRepeater',
  sectionTitle: '#sectionTitle',
  sectionDescription: '#sectionDescription',
  items: '#item1',
};

export const ITEMS_WIDGET_COMPONENT_IDS = {
  itemTitle: '#itemTitle',
  itemDescription: '#itemDescription',
  itemsRepeater: '#itemDivider',
  itemLabels: '#labels',
  itemPrice: '#itemPrice',
};

export const LABELS_WIDGET_COMPONENT_IDS = {
  labelsRepeater: '#repeater1',
  label: '#label',
};
