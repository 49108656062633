import { LABELS_WIDGET_COMPONENT_IDS } from '../../consts';
import type { Label } from 'root/types';

export class LabelsController {
  constructor(private $bind: Function) {}

  init(labels: Label[]) {
    this.$bind(LABELS_WIDGET_COMPONENT_IDS.labelsRepeater, {
      data: () =>
        (labels || []).map(({ id, ...rest }) => ({
          _id: id,
          ...rest,
        })),
      item(label: Label, $bindItem: (id: string, data: any) => void) {
        $bindItem(LABELS_WIDGET_COMPONENT_IDS.label, {
          text: () => label.name || '',
          icon: () => label.icon,
        });
      },
    });
  }
}
