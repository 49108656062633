import { SECTION_WIDGET_COMPONENT_IDS } from '../../consts';
import type { PopulatedSection } from 'root/types';

export class SectionsController {
  constructor(private $bind: Function) {}

  init(sections: PopulatedSection[]) {
    this.$bind(SECTION_WIDGET_COMPONENT_IDS.sectionsRepeater, {
      data: () =>
        (sections || []).map(({ id, ...rest }) => ({
          _id: id,
          ...rest,
        })),
      item(
        section: PopulatedSection,
        $bindItem: (id: string, data: any) => void,
      ) {
        $bindItem(SECTION_WIDGET_COMPONENT_IDS.sectionTitle, {
          text: () => section.name || '',
        });
        $bindItem(SECTION_WIDGET_COMPONENT_IDS.sectionDescription, {
          text: () => section.description || '',
          deleted: () => !section.description,
        });
        $bindItem(SECTION_WIDGET_COMPONENT_IDS.items, {
          data: () => ({
            items: section.items || [],
          }),
        });
      },
    });
  }
}
