import model from './model';
import { LabelsController } from './labelsController';

export default model.createController(({ $bind, $widget }) => {
  const labelsController = new LabelsController($bind);

  $widget.onPropsChanged((prev, next) => {
    labelsController.init(next.data?.labels);
  });
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
