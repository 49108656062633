import { MenusClient } from './MenusClient';
import { SectionsClient } from './SectionsClient';
import { PopulatedItemsClient } from './ItemsClient';
import { populateMenu } from './utils/populateMenu';
import { completeMissingEntities } from './utils/completeMissingEntities';
import type { HttpClientFactory, PopulatedMenu, Menu } from 'root/types';

export const PopulatedMenuClient: HttpClientFactory<PopulatedMenu> = (
  httpClient,
) => {
  const MenusApi = MenusClient(httpClient);
  const SectionsApi = SectionsClient(httpClient);
  const PopulatedItemsApi = PopulatedItemsClient(httpClient);

  return {
    getAll: async ({ paging }) => {
      const [menus, sections] = await Promise.all([
        MenusApi.getAll({ paging }),
        SectionsApi.getAll({ paging }),
      ]);
      const populatedItems = await PopulatedItemsApi.getAll({ paging });

      await completeMissingEntities(
        menus.data,
        sections,
        SectionsApi.getAll,
        populatedItems,
        PopulatedItemsApi.getAll,
      );

      return {
        data: menus.data.map((menu: Menu) =>
          populateMenu(menu, sections.data, populatedItems?.data ?? []),
        ) as PopulatedMenu[],
      };
    },
  };
};
