import model from './model';
import { MenuController } from './menuController';
import { getMonitoredApiCall } from '../../api/utils/getMonitoredApiCall';
import { PopulatedMenuClient } from '../../api/PopulatedMenuClient';
import { WarmupDataManager } from '../../api/utils/WarmupDataManager';
import type { PopulatedMenu } from 'root/types';

let menusPromise: any;

export default model.createController(({ $bindAll, $widget, flowAPI }) => {
  const menuController = new MenuController($bindAll);
  const warmupData = new WarmupDataManager(
    flowAPI.controllerConfig.wixCodeApi.window.warmupData,
    flowAPI.environment.isSSR,
  );

  const getMonitoredPopulatedMenuClient = () =>
    getMonitoredApiCall(
      () => PopulatedMenuClient(flowAPI.essentials.httpClient).getAll({}),
      flowAPI.reportError,
    );

  if (!menusPromise || process.env.NODE_ENV === 'test') {
    menusPromise = warmupData.manageData(
      getMonitoredPopulatedMenuClient,
      'populatedMenus',
    );
  }

  const setMenus = async (menus: PopulatedMenu[]) => {
    menuController.init(menus[0]);
  };

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});

      if (menusPromise) {
        const { data, error } = (await menusPromise) || {};
        if (!error) {
          const { data: menus } = data || {};
          if (menus?.length) {
            await setMenus(menus);
          }
        }
      }
    },
    exports: {},
  };
});
