import model from './model';
import { SectionsController } from './sectionsController';

export default model.createController(({ $bind, $widget }) => {
  const sectionsController = new SectionsController($bind);

  $widget.onPropsChanged((prev, next) => {
    sectionsController.init(next.data?.sections);
  });
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
