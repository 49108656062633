import type { Menu, Section, ShowcasePopulatedItem } from 'root/types';

export const populateMenu = (
  menu: Menu,
  sections: Section[],
  populatedItems: ShowcasePopulatedItem[],
) => {
  const menuSections = menu.sectionIds
    ?.map((sectionId) => sections.find((section) => section.id === sectionId))
    ?.filter((section) => !!section);

  const sectionsWithItems = menuSections?.map((section) => {
    const sectionItems = section?.itemIds
      ?.map((itemId) => populatedItems.find((item) => item.id === itemId))
      ?.filter((item) => !!item && item.visible !== false);

    const itemsWithLabelsAndVariants = sectionItems?.map((item) => item);

    return {
      ...section,
      items: itemsWithLabelsAndVariants,
    };
  });

  return {
    ...menu,
    sections: sectionsWithItems,
  };
};
