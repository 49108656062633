import { ITEMS_WIDGET_COMPONENT_IDS } from 'root/consts';
import type { ShowcasePopulatedItem } from 'root/types';

export class ItemsController {
  constructor(private $bind: Function) {}

  init(items: ShowcasePopulatedItem[]) {
    this.$bind(ITEMS_WIDGET_COMPONENT_IDS.itemsRepeater, {
      data: () =>
        (items || []).map(({ id, ...rest }) => ({
          _id: id,
          ...rest,
        })),
      item(item: ShowcasePopulatedItem, $bindItem: Function) {
        const itemHasLabels = (item.labels || []).length > 0;
        $bindItem(ITEMS_WIDGET_COMPONENT_IDS.itemTitle, {
          text: () => item.name || '',
        });
        $bindItem(ITEMS_WIDGET_COMPONENT_IDS.itemDescription, {
          text: () => item.description || '',
          deleted: () => !item.description,
        });
        $bindItem(ITEMS_WIDGET_COMPONENT_IDS.itemPrice, {
          text: () => item.price || '0.00',
        });
        $bindItem(ITEMS_WIDGET_COMPONENT_IDS.itemLabels, {
          deleted: () => !itemHasLabels,
          data: () => ({
            labels: item.labels || [],
          }),
        });
      },
    });
  }
}
