import model from './model';
import { ItemsController } from './itemsController';

export default model.createController(({ $bind, $widget }) => {
  const itemsController = new ItemsController($bind);

  $widget.onPropsChanged((prev, next) => {
    itemsController.init(next.data?.items);
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
